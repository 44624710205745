import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { AboutComponent } from './components/pages/about/about.component';
import { AffiliateComponent } from './components/pages/affiliate/affiliate.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { EfaSstComponent } from './components/pages/high-speed-doors/efa-sst/efa-sst.component';
import { BrouchersComponent } from './components/pages/brouchers/brouchers.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { HighSpeedDoorsComponent } from './components/pages/high-speed-doors/high-speed-doors.component';
import { ShuttersComponent } from './components/pages/shutters/shutters.component';
import { AutomatedGatesComponent } from './components/pages/automated-gates/automated-gates.component';
import { DoorsComponent } from './components/pages/doors/doors.component';
import { GatesComponent } from './components/pages/gates/gates.component';
import { RapidDoorsComponent } from './components/pages/rapid-doors/rapid-doors.component';
import { BoomBarriersComponent } from './components/pages/gates/boom-barriers/boom-barriers.component';
import { CantileverGatesComponent } from './components/pages/gates/cantilever-gates/cantilever-gates.component';
import { RetractableGatesComponent } from './components/pages/gates/retractable-gates/retractable-gates.component';
import { SlidingGatesComponent } from './components/pages/gates/sliding-gates/sliding-gates.component';
import { SwingGatesComponent } from './components/pages/gates/swing-gates/swing-gates.component';
import { TeliscopicGatesComponent } from './components/pages/gates/teliscopic-gates/teliscopic-gates.component';
import { FoldingDoorsComponent } from './components/pages/doors/folding-doors/folding-doors.component';
import { HangarDoorsComponent } from './components/pages/doors/hangar-doors/hangar-doors.component';
// tslint:disable-next-line:max-line-length
import { IndustrialSectionalDoorsComponent } from './components/pages/doors/industrial-sectional-doors/industrial-sectional-doors.component';
import { IndustrialSlidingDoorsComponent } from './components/pages/doors/industrial-sliding-doors/industrial-sliding-doors.component';
import { SectionalDoorsComponent } from './components/pages/doors/sectional-doors/sectional-doors.component';
import { SectionalOverHeadDoorsComponent } from './components/pages/doors/sectional-over-head-doors/sectional-over-head-doors.component';

import { DockLevellersComponent } from './components/pages/dock/dock-levellers/dock-levellers.component';
import { DockSheltersComponent } from './components/pages/dock/dock-shelters/dock-shelters.component';
import { DockComponent } from './components/pages/dock/dock.component';

import { FreezerDoorsComponent } from './components/pages/rapid-doors/freezer-door/freezer-doors.component';
import { FreezerplusDoorsComponent } from './components/pages/rapid-doors/freezerplus-door/freezerplus-doors.component';
import { RollupDoorsComponent } from './components/pages/rapid-doors/rollup-door/rollup-doors.component';
import { StackingDoorsComponent } from './components/pages/rapid-doors/stacking-door/stacking-doors.component';
import { ZipperDoorsComponent } from './components/pages/rapid-doors/zipper-door/zipper-doors.component';
import { MegagiantDoorsComponent } from './components/pages/rapid-doors/megagiant-door/megagiant-doors.component';
import { MotorsComponent } from './components/pages/motors/motors.component';
import { PeripheryComponent } from './components/pages/high-speed-doors/periphery/periphery.component';
import { ClientsComponent } from './components/pages/clients/clients.component';

const routes: Routes = [

    {path: 'home', component: HomeOneComponent},
    {path: 'about', component: AboutComponent},
    {path: 'affiliate', component: AffiliateComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'efa-sst', component: EfaSstComponent},
    {path: 'pheripery', component:  PeripheryComponent},
    {path: 'brouchers', component: BrouchersComponent},
    {path: 'gallery', component: GalleryComponent},
    {path: 'products', component: ProductsComponent},
    {path: 'high-speed-doors', component: HighSpeedDoorsComponent},
    {path: 'automated-gates', component: AutomatedGatesComponent},
    {path: 'doors', component: DoorsComponent},
    {path: 'shutters', component: ShuttersComponent},
    {path: 'gates', component: GatesComponent},
    {path: 'rapid-doors', component: RapidDoorsComponent},
    {path: 'freezer-door', component: FreezerDoorsComponent},
    {path: 'freezerplus-door', component: FreezerplusDoorsComponent},
    {path: 'stacking-door', component: StackingDoorsComponent},
    {path: 'zipper-door', component: ZipperDoorsComponent},
    {path: 'megagiant-door', component: MegagiantDoorsComponent},
    {path: 'rollup-door', component: RollupDoorsComponent},
    {path: 'boom-barrier', component: BoomBarriersComponent},
    {path: 'retractable-gate', component: RetractableGatesComponent},
    {path: 'sliding-gate', component: SlidingGatesComponent},
    {path: 'swing-gate', component: SwingGatesComponent},
    {path: 'cantilever-gate', component: CantileverGatesComponent},
    {path: 'folding-door', component: FoldingDoorsComponent},
    {path: 'hangar-door', component: HangarDoorsComponent},
    {path: 'ind-sectional-door', component: IndustrialSectionalDoorsComponent},
    {path: 'ind-sliding-door', component: IndustrialSlidingDoorsComponent},
    {path: 'sectional-door', component: SectionalDoorsComponent},
    {path: 'sectional-over-head-door', component: SectionalOverHeadDoorsComponent},
    {path: 'dock-levelers', component: DockLevellersComponent},
    {path: 'dock-shelters', component: DockSheltersComponent},
    {path: 'dock', component: DockComponent},
    {path: 'motors', component: MotorsComponent},
    {path: 'clients', component: ClientsComponent},
    {path: '', redirectTo: '/home', pathMatch: 'full'},
    {path: '**', redirectTo: '/home'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
