import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from '../../../services/meta-service.service';
@Component({
  selector: 'app-rapid-doors',
  templateUrl: './rapid-doors.component.html',
  styleUrls: ['./rapid-doors.component.scss']
})
export class RapidDoorsComponent implements OnInit {
	page: any;
	freezerwhite = true;
	freezerblack = false;
	
	freezerpluswhite = false;
	freezerplusblack = true;
	
	rollupwhite = false;
	rollupblack = true;
	
	stackingwhite = false;
	stackingblack = true;
	
	zipperwhite = false;
	zipperblack = true;
	
	megagiantwhite = false;
	megagiantblack = true;

 constructor(private route: ActivatedRoute, private metaService: MetaService) { }
  ngOnInit(): void {
    this.metaService.setCanonicalURL();
	this.route.queryParams.subscribe(params => {
        this.page = params.page;
    });
  }
  
  turnWhite(imgname) 
  {
	if(imgname === 'freezer')
	 {
	this.freezerwhite = true;
	this.freezerblack = false;
	
	this.freezerpluswhite = false;
	this.freezerplusblack = true;
	
	this.rollupwhite = false;
	this.rollupblack = true;
	
	this.stackingwhite = false;
	this.stackingblack = true;
	
	this.zipperwhite = false;
	this.zipperblack = true;
	
	this.megagiantwhite = false;
	this.megagiantblack = true;
	 }
	 else if (imgname === 'freezerplus')
	 {
	this.freezerwhite = false;
	this.freezerblack = true;
	
	this.freezerpluswhite = true;
	this.freezerplusblack = false;
	
	this.rollupwhite = false;
	this.rollupblack = true;
	
	this.stackingwhite = false;
	this.stackingblack = true;
	
	this.zipperwhite = false;
	this.zipperblack = true;
	
	this.megagiantwhite = false;
	this.megagiantblack = true;
	  }
	  else if (imgname === 'rollup')
	 {
	this.freezerwhite = false;
	this.freezerblack = true;
	
	this.freezerpluswhite = false;
	this.freezerplusblack = true;
	
	this.rollupwhite = true;
	this.rollupblack = false;
	
	this.stackingwhite = false;
	this.stackingblack = true;
	
	this.zipperwhite = false;
	this.zipperblack = true;
	
	this.megagiantwhite = false;
	this.megagiantblack = true;
	  }
	  else if (imgname === 'stacking')
	 {
	this.freezerwhite = false;
	this.freezerblack = true;
	
	this.freezerpluswhite = false;
	this.freezerplusblack = true;
	
	this.rollupwhite = false;
	this.rollupblack = true;
	
	this.stackingwhite = true;
	this.stackingblack = false;
	
	this.zipperwhite = false;
	this.zipperblack = true;
	
	this.megagiantwhite = false;
	this.megagiantblack = true;
	  }
	  else if (imgname === 'zipper')
	 {
	this.freezerwhite = false;
	this.freezerblack = true;
	
	this.freezerpluswhite = false;
	this.freezerplusblack = true;
	
	this.rollupwhite = false;
	this.rollupblack = true;
	
	this.stackingwhite = false;
	this.stackingblack = true;
	
	this.zipperwhite = true;
	this.zipperblack = false;
	
	this.megagiantwhite = false;
	this.megagiantblack = true;
	  }
	  else if (imgname === 'megagiant')
	 {
	this.freezerwhite = false;
	this.freezerblack = true;
	
	this.freezerpluswhite = false;
	this.freezerplusblack = true;
	
	this.rollupwhite = false;
	this.rollupblack = true;
	
	this.stackingwhite = false;
	this.stackingblack = true;
	
	this.zipperwhite = false;
	this.zipperblack = true;
	
	this.megagiantwhite = true;
	this.megagiantblack = false;
	  }
	  else{
	this.freezerwhite = true;
	this.freezerblack = false;
	
	this.freezerpluswhite = false;
	this.freezerplusblack = true;
	
	this.rollupwhite = false;
	this.rollupblack = true;
	
	this.stackingwhite = false;
	this.stackingblack = true;
	
	this.zipperwhite = false;
	this.zipperblack = true;
	
	this.megagiantwhite = false;
	this.megagiantblack = true;
	  }
  }
  

}
