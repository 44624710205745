<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Doors</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/home">Home</a></li>
                        <li><a routerLink="/products">Products</a></li>
                        <li>Doors</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="contact-info-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="h2-cus">DOORS</h2>
            <!-- </div>
        <div class="txt-center"> -->


            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">

                <li class="nav-item  ">
                    <a class="nav-link" id="bi-folding-tab" (click)="turnWhite('bi')" [ngClass]="{'active' : page == 'bi-folding'}" data-toggle="pill"
                        href="#bi-folding" role="tab" aria-controls="bi-folding" aria-selected="true"> <i class="">
                            <img class="cus-icon" [hidden]="!biblack" src="assets/img/icons/Doors/biFolding-doors.png" alt="Image">
                            <img class="cus-icon" [hidden]="!biwhite" src="assets/img/icons/Doors/bifolding-door-white.PNG" alt="Image">
                        </i>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="folding-tab" (click)="turnWhite('folding')" [ngClass]="{'active' : page == 'folding'}" data-toggle="pill"
                        href="#folding" role="tab" aria-controls="folding" aria-selected="false"> <i class="">
                             <img [hidden]="!foldingblack" class="cus-icon" src="assets/img/icons/Doors/folding-doors.png" alt="Image">
                             <img [hidden]="!foldingwhite" class="cus-icon" src="assets/img/icons/Doors/folding-door-white.PNG" alt="Image">
                            </i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link arrow_box " (click)="turnWhite('hangar')" [ngClass]="{'active' : page == 'hangar'}" id="hangar-tab"
                        data-toggle="pill" href="#hangar" role="tab" aria-controls="hangar" aria-selected="true">
                        <i class="">
                             <img [hidden]="!hangarblack" class="cus-icon" src="assets/img/icons/Doors/hangar-doors.png" alt="Image">
                             <img [hidden]="!hangarwhite" class="cus-icon" src="assets/img/icons/Doors/hangar-door-white.PNG" alt="Image">
                            </i>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="ind-sectional-doors-tab" (click)="turnWhite('indsec')" [ngClass]="{'active' : page == 'ind-sectional-doors'}" data-toggle="pill"
                        href="#ind-sectional-doors" role="tab" aria-controls="ind-sectional-doors" aria-selected="false"> <i class="">
                             <img [hidden]="!indsecblack" class="cus-icon" src="assets/img/icons/Doors/ind-sectional-doors.png" alt="Image">
                             <img [hidden]="!indsecwhite" class="cus-icon" src="assets/img/icons/Doors/ind-sec-door-white.PNG" alt="Image">
                            </i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="ind-sliding-doors-tab" (click)="turnWhite('indsli')" [ngClass]="{'active' : page == 'ind-sliding-doors'}"
                        data-toggle="pill" href="#ind-sliding-doors" role="tab" aria-controls="ind-sliding-doors"
                        aria-selected="false"> <i class="">
                             <img class="cus-icon" [hidden]="!indsliblack" src="assets/img/icons/Doors/ind-sliding-door.png"  alt="Image">
                             <img class="cus-icon" [hidden]="!indsliwhite" src="assets/img/icons/Doors/ind-sliding-door-white.PNG"  alt="Image">

                            </i></a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="sectional-doors-tab" (click)="turnWhite('sec')" [ngClass]="{'active' : page == 'sectional-doors'}"
                        data-toggle="pill" href="#sectional-doors" role="tab" aria-controls="sectional-doors"
                        aria-selected="false"> <i class="">
                            <img class="cus-icon" [hidden]="!secblack" src="assets/img/icons/Doors/sectional-doors.png" alt="Image">
                            <img class="cus-icon" [hidden]="!secwhite" src="assets/img/icons/Doors/sectional-door-white.PNG" alt="Image">

                        </i></a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="sectional-over-head-doors-tab" (click)="turnWhite('secov')" [ngClass]="{'active' : page == 'sectional-over-head-doors'}"
                        data-toggle="pill" href="#sectional-over-head-doors" role="tab" aria-controls="sectional-over-head-doors"
                        aria-selected="false"> <i class="">
                            <img class="cus-icon" [hidden]="!secovblack" src="assets/img/icons/Doors/section-over-head-doors.png"alt="Image">
                            <img class="cus-icon" [hidden]="!secovwhite" src="assets/img/icons/Doors/sec-over-head-door-white.PNG"alt="Image">
                        </i></a>
                </li>


            </ul>
        </div>



        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'bi-folding'}" id="bi-folding" role="tabpanel"
                aria-labelledby="bi-folding-tab">
                <app-bi-folding-doors></app-bi-folding-doors>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'folding'}" id="folding" role="tabpanel"
                aria-labelledby="folding-tab">
               <app-folding-doors></app-folding-doors>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'hangar'}" id="hangar" role="tabpanel"
                aria-labelledby="hangar-tab">
               <app-hangar-doors></app-hangar-doors>
            </div>

            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'ind-sectional-doors'}" id="ind-sectional-doors"
                role="tabpanel" aria-labelledby="ind-sectional-doors-tab">
               <app-industrial-sectional-doors></app-industrial-sectional-doors>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'ind-sliding-doors'}" id="ind-sliding-doors"
                role="tabpanel" aria-labelledby="ind-sliding-doors-tab">
               <app-industrial-sliding-doors></app-industrial-sliding-doors>
            </div>

            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'sectional-doors'}" id="sectional-doors"
                role="tabpanel" aria-labelledby="sectional-doors-tab">
               <app-sectional-doors></app-sectional-doors>
            </div>

            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'sectional-over-head-doors'}" id="sectional-over-head-doors"
                role="tabpanel" aria-labelledby="sectional-over-head-doors-tab">
              <app-sectional-over-head-doors></app-sectional-over-head-doors>
            </div>


        </div>
    </div>
</section>
