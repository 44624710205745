<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Clients</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/home">Home </a></li>
                        <li>Clients</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="blog-area ptb-100 ">
    <div class="container">
    <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
        <h2>OUR CLIENTS</h2>
        <p>"GATEMAAN : GERMAN TECHNOLOGY TAILORED FOR INDIAN EXCELLENCE".
            <br>From last 30 years we follows same in all aspects.</p>
    </div>
<div  class="row margin-bottom">
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/1.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/2.png" alt="Image"  type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/3.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
</div>
<div  class="row margin-bottom">
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/4.png" alt="Image"  type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/5.png" alt="Image"  type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img  src="assets/img/our-clients/6.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
</div>
<div  class="row margin-bottom">
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/7.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/8.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/9.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
</div>
<div  class="row margin-bottom">
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/10.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img  src="assets/img/our-clients/11.png" alt="Image" type="image/png" class="img-fluid">

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/12.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/13.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/14.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/15.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/16.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/17.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/18.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/19.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/20.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/21.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/22.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/23.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/24.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/25.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/26.png" alt="Image" type="image/png" class="img-fluid" >

        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/27.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/28.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/29.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/30.jpg" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/31.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/32.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/33.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/34.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/35.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/36.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/37.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/38.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/39.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/40.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
    <div  class="col-lg-4 col-md-6">
        <div  class="review-box">
            <img src="assets/img/our-clients/41.png" alt="Image" type="image/png" class="img-fluid" >
        </div>
    </div>
</div>
</div>
</section>

