import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { AboutComponent } from './components/pages/about/about.component';
import { AffiliateComponent } from './components/pages/affiliate/affiliate.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { ExpansionComponent } from './expansion/expansion.component';
import { MiniFooterComponent } from './components/mini-footer/mini-footer.component';
import { EfaSstComponent } from './components/pages/high-speed-doors/efa-sst/efa-sst.component';
import { EfaSrtComponent } from './components/pages/high-speed-doors/efa-srt/efa-srt.component';
import { EfaSttComponent } from './components/pages/high-speed-doors/efa-stt/efa-stt.component';
import { EfaSrtMtlComponent } from './components/pages/high-speed-doors/efa-srt-mtl/efa-srt-mtl.component';
import { EfaStrComponent } from './components/pages/high-speed-doors/efa-str/efa-str.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { BrouchersComponent } from './components/pages/brouchers/brouchers.component';
import { HighSpeedDoorsComponent } from './components/pages/high-speed-doors/high-speed-doors.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { ShuttersComponent } from './components/pages/shutters/shutters.component';
import { AutomatedGatesComponent } from './components/pages/automated-gates/automated-gates.component';
import { PvcShuttersComponent } from './components/pages/shutters/pvc-shutters/pvc-shutters.component';
import { GatesComponent } from './components/pages/gates/gates.component';
import { SlidingGatesComponent } from './components/pages/gates/sliding-gates/sliding-gates.component';
import { TeliscopicGatesComponent } from './components/pages/gates/teliscopic-gates/teliscopic-gates.component';
import { CantileverGatesComponent } from './components/pages/gates/cantilever-gates/cantilever-gates.component';
import { RetractableGatesComponent } from './components/pages/gates/retractable-gates/retractable-gates.component';
import { SwingGatesComponent } from './components/pages/gates/swing-gates/swing-gates.component';
import { BoomBarriersComponent } from './components/pages/gates/boom-barriers/boom-barriers.component';
import { BiFoldingDoorsComponent } from './components/pages/doors/bi-folding-doors/bi-folding-doors.component';
import { FoldingDoorsComponent } from './components/pages/doors/folding-doors/folding-doors.component';
import { HangarDoorsComponent } from './components/pages/doors/hangar-doors/hangar-doors.component';
import { IndustrialSlidingDoorsComponent } from './components/pages/doors/industrial-sliding-doors/industrial-sliding-doors.component';
import { SectionalDoorsComponent } from './components/pages/doors/sectional-doors/sectional-doors.component';
import { SectionalOverHeadDoorsComponent } from './components/pages/doors/sectional-over-head-doors/sectional-over-head-doors.component';
import { DockLevellersComponent } from './components/pages/dock/dock-levellers/dock-levellers.component';
import { DockSheltersComponent } from './components/pages/dock/dock-shelters/dock-shelters.component';
import { AluminiumShuttersComponent } from './components/pages/shutters/aluminium-shutters/aluminium-shutters.component';
import { FireRatedShuttersComponent } from './components/pages/shutters/fire-rated-shutters/fire-rated-shutters.component';
import { GalvalumeShuttersComponent } from './components/pages/shutters/galvalume-shutters/galvalume-shutters.component';
import { GalvanisedShuttersComponent } from './components/pages/shutters/galvanised-shutters/galvanised-shutters.component';
import { PolycarbonateShuttersComponent } from './components/pages/shutters/polycarbonate-shutters/polycarbonate-shutters.component';
import { SsShuttersComponent } from './components/pages/shutters/ss-shutters/ss-shutters.component';
import { PerforatedShuttersComponent } from './components/pages/shutters/perforated-shutters/perforated-shutters.component';
// tslint:disable-next-line:max-line-length
import { IndustrialSectionalDoorsComponent } from './components/pages/doors/industrial-sectional-doors/industrial-sectional-doors.component';
import { DockComponent } from './components/pages/dock/dock.component';
import { DoorsComponent } from './components/pages/doors/doors.component';
import {IvyGalleryModule} from 'angular-gallery';
import { PeripheryComponent } from './components/pages/high-speed-doors/periphery/periphery.component';
import { LightboxModule } from 'ngx-lightbox';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ClientsComponent } from './components/pages/clients/clients.component';
@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    HomeOneComponent,
    NavbarComponent,
    AboutComponent,
    AffiliateComponent,
    PrivacyPolicyComponent,
    ContactComponent,
    ComingSoonComponent,
    ErrorComponent,
    ExpansionComponent,
    MiniFooterComponent,
    EfaSstComponent,
    EfaSrtComponent,
    EfaSttComponent,
    EfaSrtMtlComponent,
    EfaStrComponent,
    GalleryComponent,
    BrouchersComponent,
    HighSpeedDoorsComponent,
    ProductsComponent,
    ShuttersComponent,
    AutomatedGatesComponent,
    DoorsComponent,
    PvcShuttersComponent,
    AluminiumShuttersComponent,
    FireRatedShuttersComponent,
    GalvanisedShuttersComponent,
    GalvalumeShuttersComponent,
    PolycarbonateShuttersComponent,
    SsShuttersComponent,
    GatesComponent,
    SlidingGatesComponent,
    TeliscopicGatesComponent,
    CantileverGatesComponent,
    RetractableGatesComponent,
    SwingGatesComponent,
    BoomBarriersComponent,
    BiFoldingDoorsComponent,
    FoldingDoorsComponent,
    HangarDoorsComponent,
    IndustrialSlidingDoorsComponent,
    SectionalDoorsComponent,
    SectionalOverHeadDoorsComponent,
    DockLevellersComponent,
    DockSheltersComponent,
    PerforatedShuttersComponent,
    IndustrialSectionalDoorsComponent,
    DockComponent,

    PeripheryComponent,
    ClientsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatSidenavModule, MatIconModule, MatButtonModule,
    IvyGalleryModule,
    LightboxModule,
    CarouselModule,
    ReactiveFormsModule, // Add if needed
   FormsModule,     // Add if needed
   HttpClientModule
//    RouterModule.forRoot(routes, { useHash: true })

  ],
  providers: [
    // {provide : LocationStrategy , useClass: HashLocationStrategy}
     ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],

})
export class AppModule { }
