import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from '../../../services/meta-service.service';
@Component({
  selector: 'app-dock',
  templateUrl: './dock.component.html',
  styleUrls: ['./dock.component.scss']
})
export class DockComponent implements OnInit {

    page: any;
    dclevelwhite = true;
    dclevelblack = false;

    shelterblack = true;
    shelterwhite = false;


    constructor(private route: ActivatedRoute, private metaService: MetaService) { }
    ngOnInit(): void {
        this.metaService.setCanonicalURL();
      this.route.queryParams.subscribe(params => {
          this.page = params.page;
      });  }


    turnWhite(imgname) {
        if (imgname === 'docklevel') {
            this.dclevelwhite = true;
            this.dclevelblack = false;

            this.shelterblack = true;
            this.shelterwhite = false;


        } else if (imgname === 'shelter') {
            this.dclevelwhite = false;
            this.dclevelblack = true;

            this.shelterblack = false;
            this.shelterwhite = true;
        } else {
            this.dclevelwhite = true;
            this.dclevelblack = false;

            this.shelterblack = true;
            this.shelterwhite = false;
        }
    }

}
