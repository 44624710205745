<mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step == 0" class="wow fadeInUp animated animated" style="visibility: visible;"
        (opened)="setStep(0)" hideToggle>
        <mat-expansion-panel-header>
            <header>
                <div class="overlay"></div>
                <h1 class="efa-head"><img class="efa-part" src="assets/img/Efalex Logo.PNG" alt="Homepage"></h1>
                <video [muted]="true" autoplay playsinline loop>
                    <source src="assets/videos/efa-new.mp4" type="video/mp4">
                </video>
                <div class="container h-100">
                    <div class="d-flex h-100 text-center align-items-center">
                        <div class="w-100 text-white">
                            <h1 class="content">High Speed Doors</h1>
                            <hr class="style-two animate-flicker">
                            <div class="single-location video-btn">
                                <i class="bx bxs-hand-up"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </mat-expansion-panel-header>
        <mat-form-field>
            <div class="spacer"></div>
            <section class="hosting-services-area pb-70">
                <div class="container">
                    <div class="section-title">
                        <h2>High Speed Doors</h2>
                        <p>High speed doors excel during intense operation and in demanding conditions. Fast-acting fabric and
                            insulated doors can improve efficiency, safety and hygiene in facilities where doors open and close
                            frequently i.e manufacturing sites, cold stores and cleanroom type facilities.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/High Speed/SST/sst.jpg" class="pro-img" alt="Image" type="image/jpeg">
                                <!-- <img class="cus-icon" src="assets/img/icons/HSD/sst.png" alt="Image" type="image/jpeg"> -->
                            </div>
                            <div class="single-technology">
                                 <h3>EFA - SST</h3>
                                <p class="p-cus">Doors in cooled rooms must be able to withstand very high loads. Top
                                    quality and perfect workmanship are a prerequisite for all components to withstand
                                    cold and humidity.</p>
                                <a class="read-more" [routerLink]="['/high-speed-doors']"
                                    [queryParams]="{page: 'sst'}">Read More <i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/High Speed/STT/stt.jpg" class="pro-img" alt="Image" type="image/jpeg">
                                <!-- <img src="assets/img/shape/technology-icon-bg.png" alt="Image" type="image/jpeg">
                                <img class="cus-icon" src="assets/img/icons/HSD/stt.png" alt="Image" type="image/jpeg"> -->
                            </div>
                            <div class="single-technology">
                                <h3>EFA - STT</h3>
                                <p class="p-cus">Due to its laths which are made of crystal clear acrylic glass, the
                                    door leaf of the EFA-STT® is transparent at more than 70% - this fact makes it a
                                    worldwide unique high-speed door: robust but nevertheless completely transparent.
                                </p>
                                <a class="read-more" [routerLink]="['/high-speed-doors']"
                                    [queryParams]="{page: 'stt'}">Read More <i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/High Speed/SRT/srt.jpg" class="pro-img" alt="Image" type="image/jpeg">
                                <!-- <img src="assets/img/shape/technology-icon-bg.png" alt="Image" type="image/jpeg">
                                <img class="cus-icon" src="assets/img/icons/HSD/srt.png" alt="Image" type="image/jpeg"> -->
                            </div>
                            <div class="single-technology">
                                <h3>EFA - SRT</h3>
                                <p class="p-cus">The EFA-SRT® is a real allrounder! It can be used as inside door or as
                                    additional hall closure. For each opening which is extremely often passed by
                                    forklift trucks, the high-speed roll-up door /from EFAFLEX is a high-quality
                                    solution.</p>
                                <a class="read-more" [routerLink]="['/high-speed-doors']"
                                    [queryParams]="{page: 'srt'}">Read More <i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/High Speed/STR/str.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">
                                <h3>EFA - STR</h3>
                                <p class="p-cus">The EFA-STR opens with an incredible speed of more than 4 m/s. With the
                                    EFA-STR, your logistic processes become faster and more efficient. Our fastest door
                                    – a combination of the spiral-shaped door guidance and the flexible door blade
                                    guarantees you an optimal traffic flow</p>
                                <a class="read-more" [routerLink]="['/high-speed-doors']"
                                    [queryParams]="{page: 'str'}">Read More <i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/High Speed/MTL/mtl.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">
                                <h3>EFA - SFT</h3>
                                <p class="p-cus">EFA-SFT door has been designed for all applications within
                                    intensive logistics processes and is particularly suitable for both commercial and
                                    industrial use in closed areas that are not exposed to wind and/or weather
                                    conditions.</p>
                                <a class="read-more" [routerLink]="['/high-speed-doors']"
                                    [queryParams]="{page: 'srt-mtl'}">Read More <i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>


                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/High Speed/SFT/control-box.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">
                                <h3>EFA - Periphery</h3>
                                <p class="p-cus">Optimised functionality, compact and modern design all describe our all new periphery
                                    Fully compatible, incredibly reliable and significant power reserves are amongst its many key features.
                                </p>
                                <a class="read-more" [routerLink]="['/high-speed-doors']"
                                [queryParams]="{page: 'pheriperi'}">Read More <i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>


                    </div>
                    <div class="col-md-12 col-sm-12 text-center">
                        <button class="default-btn btn-two" [routerLink]="['/high-speed-doors']"
                            [queryParams]="{page: 'sst'}" type="submit">
                            Explore More
                        </button>
                    </div>
                </div>
            </section>
        </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step == 1" class="wow fadeInUp animated animated" style="visibility: visible;"
        (opened)="setStep(1)" hideToggle>
        <mat-expansion-panel-header>
            <header>
                <div class="overlay"></div>
                <video [muted]="true" autoplay playsinline loop>
                    <source src="assets/videos/Rolling Shutter Video.mp4" type="video/mp4">
                </video>
                <div class="container h-100">
                    <div class="d-flex h-100 text-center align-items-center">
                        <div class="w-100 text-white">
                            <h1 class="content">Rolling Shutters</h1>
                            <hr class="style-two animate-flicker">
                            <div class="single-location video-btn">
                                <i class="bx bxs-hand-up"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        <!--  <div class="wrapper">
                <h1 class="content">Rolling Shutters</h1>
                <hr class="style-two animate-flicker">
                <div class="single-location video-btn">
                    <i class="bx bxs-hand-up"></i>
                </div>
                <div class="sliding-background2">
                    <span id="overlay"></span>
                </div>
            </div>-->
        </mat-expansion-panel-header>
        <mat-form-field>
            <div class="spacer"></div>
            <section class="hosting-services-area pb-70">
                <div class="container">
                    <div class="section-title">
                        <h2>Rolling Shutter</h2>
                        <p>A roller shutter, roller door is a type of door or window shutter consisting of many horizontal slats (or
                            sometimes bars or web systems) hinged together.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Shutters/aluminium-rolling-shutters.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Aliminium Shutters</h3>
                                <p>The rolling shutter made of aluminum is called aluminum shutters. Aluminum shutters are ideal for all areas of your home, showrooms and also for offices. </p>
                                <a class="read-more" [routerLink]="['/shutters']"
                                    [queryParams]="{page: 'aluminium'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Shutters/stainless-steel-roller-shutter.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Stainless Steel Shutters</h3>
                                <p>The Stainless Steel Rolling Shutter, perhaps more than any other door has a diverse range of applications. The shutter has very good Corrosion, </p>
                                <a class="read-more" [routerLink]="['/shutters']"  [queryParams]="{page: 'ss'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Shutters/galvalume.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Galvalume Shutters</h3>
                                <p>Technologically advanced rolling shutter with Indian design, Galvalume material consist of 55% aluminium 43.4% Zinc and 1.6% silicon.

                                </p>
                                <a class="read-more" [routerLink]="['/shutters']" [queryParams]="{page: 'galvalume'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Shutters/polycarbonate-rolling-shutter.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Polycarbonate Shutters</h3>
                                <p>Polycarbonate is a manmade material that's especially suitable for roller shutters. Like glass, it's transparent, but unlike glass, it's very hard to break.</p>
                                <a class="read-more" [routerLink]="['/shutters']" [queryParams]="{page: 'polycarbonate'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Shutters/perforated.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Perforated Shutters</h3>
                                <p>The rolling shutter made up of Galvanized Iron (GI) sheet lathes/ panels is called Galvanized-rolling shutter.</p>
                                <a class="read-more"  [routerLink]="['/shutters']" [queryParams]="{page: 'perforated'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Shutters/galvanised.JPG" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Galvanised Shutters</h3>
                                <p>The rolling shutter made up of Galvanized Iron (GI) sheet lathes/ panels is called Galvanized-rolling shutter.</p>
                                <a class="read-more" [routerLink]="['/shutters']" [queryParams]="{page: 'galvanised'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Shutters/fire-rated2.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Fire Rated Shutters</h3>
                                <p>Fire Rated Rolling Shutters are physical barriers that protect wall openings from spread of fire. They provide automatic closing in the event of fire detection. </p>
                                <a class="read-more" [routerLink]="['/shutters']" [queryParams]="{page: 'fire-rated'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Shutters/pvc.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">
                                <h3>PVC</h3>
                                <p>
                                    The rolling shutter made up PVS lathes/panels is called PVC rolling shutter. PVC rolling shutters add great aesthetic look with high security and are of low cost

                                    </p>
                                <a class="read-more" [routerLink]="['/shutters']" [queryParams]="{page: 'pvc'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Shutters/ms.JPG" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">
                                <h3>Mild Steel Shutter</h3>
                                <p>
                                    The rolling shutter made up MS lathes/panels is called MS rolling shutter. MS rolling shutters add great aesthetic look with high security and are of low cost

                                    </p>
                                <a class="read-more" [routerLink]="['/shutters']" [queryParams]="{page: 'ms'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Shutters/motor.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">
                                <h3>Motors</h3>
                                <p>
                                    Our Gatemaan Motors are ideal for domestic and light - industrial use. This motors are exceptionally quick and easy to install.

                                    </p>
                                <a class="read-more" [routerLink]="['/shutters']" [queryParams]="{page: 'ms'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12 text-center">
                        <button class="default-btn btn-two" type="submit" [routerLink]="['/shutters']"
                        [queryParams]="{page: 'aluminium'}">
                            Explore More
                        </button>
                    </div>
                </div>
            </section>
        </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step == 2" class="wow fadeInUp animated animated" style="visibility: visible;"
        (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
            <header>
                <div class="overlay"></div>
                <video [muted]="true" autoplay playsinline loop>
                    <source src="assets/videos/gates.mp4" type="video/mp4">
                </video>
                <div class="container h-100">
                    <div class="d-flex h-100 text-center align-items-center">
                        <div class="w-100 text-white">
                            <h1 class="content">Automated Gates</h1>
                            <hr class="style-two animate-flicker">
                            <div class="single-location video-btn">
                                <i class="bx bxs-hand-up"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </mat-expansion-panel-header>
        <mat-form-field>
            <div class="spacer"></div>
            <section class="hosting-services-area pb-70">
                <div class="container">
                    <div class="section-title">
                        <h2>Automated Gates</h2>
                        <p>An Automatic gate is an entrance gate which can be opened and closed via an electric
                            powered mechanism.</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Gates/sliding.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">
                                <h3>Sliding Gates</h3>
                                <p>The type of slide gate used depends on the available space for the gate, volume of use, security requirements, type of surface at the gate opening and the project budget.</p>
                                <a class="read-more" [routerLink]="['/gates']"
                                [queryParams]="{page: 'sliding'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Gates/telescopic.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Telescopic Gates</h3>
                                <p>Telescopic gate: Telescopic gate is a two piece sliding gate but both pieces slide in the same direction so you only need half the width of the gateway + 30cm.</p>
                                <a class="read-more" [routerLink]="['/gates']"
                                [queryParams]="{page: 'telesocpic'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Gates/cantilever.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Cantilever Gates</h3>
                                <p>A cantilever gate is supported from rails that run along the inside of a fence structure. This is where it gets its name from – “cantilever” means “hangs over” </p>
                                <a class="read-more"  [routerLink]="['/gates']"
                                [queryParams]="{page: 'cantilever'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Gates/retractable.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Retractable Gates</h3>
                                <p>
                                    Retractable gates offer best of both worlds, allowing for sizable openings to be controlled without requiring costly installations and space.</p>
                                <a class="read-more"  [routerLink]="['/gates']"
                                [queryParams]="{page: 'retractablle'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Gates/swing.jpg"  class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Swing Gates</h3>
                                <p>A gate that swings in either direction and closes when released. A swing gate has one or two doors that swing open on hinges attached to poles.</p>
                                <a class="read-more"  [routerLink]="['/gates']"
                                [queryParams]="{page: 'swing'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Gates/boom.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Boom Barriers</h3>
                                <p>A boom barrier, also known as a boom gate, is a bar, or pole pivoted to allow the boom to block vehicular access through a controlled point. </p>
                                <a class="read-more"  [routerLink]="['/gates']"
                                [queryParams]="{page: 'boom'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Gates/motor.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Motors</h3>
                                <p>Our Gatemaan Motors are ideal for domestic and light - industrial use. This motors are exceptionally quick and easy to install. </p>
                                <a class="read-more"  [routerLink]="['/gates']"
                                [queryParams]="{page: 'boom'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12 text-center">
                        <button class="default-btn btn-two" type="submit" [routerLink]="['/gates']"
                        [queryParams]="{page: 'sliding'}">
                            Explore More
                        </button>
                    </div>
                </div>
            </section>
        </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step == 3" class="wow fadeInUp animated animated" style="visibility: visible;"
        (opened)="setStep(3)" hideToggle>
        <mat-expansion-panel-header>
            <header>
                <div class="overlay"></div>
                <video [muted]="true" autoplay playsinline loop>
                    <source src="assets/videos/doors.mp4" type="video/mp4">
                </video>
                <div class="container h-100">
                    <div class="d-flex h-100 text-center align-items-center">
                        <div class="w-100 text-white">
                            <h1 class="content">Doors</h1>
                            <hr class="style-two animate-flicker">
                            <div class="single-location video-btn">
                                <i class="bx bxs-hand-up"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </mat-expansion-panel-header>
        <mat-form-field>
            <div class="spacer"></div>
            <section class="hosting-services-area pb-70">
                <div class="container">
                    <div class="section-title">
                        <h2>Doors</h2>
                        <p>Doors are usually made in several jointed panels that roll up on tracks across the garage ceiling, or
                            into a roll above the doorway.</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Doors/bi.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">
                                <h3>Bi-Folding Doors</h3>
                                <p>Bi-Folding doors are doors that slide open, made from a series of panels that fold up against the wall like a concertina. </p>
                                <a class="read-more" [routerLink]="['/doors']"
                                [queryParams]="{page: 'bi-folding'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Doors/folding.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">
                                <h3>Folding Doors</h3>
                                <p>Bi-Folding doors are doors that slide open, made from a series of panels that fold up against the wall like a concertina. </p>
                                <a class="read-more" [routerLink]="['/doors']"
                                [queryParams]="{page: 'folding'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Doors/hangar4.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">
                                <h3>Hangar Doors</h3>
                                <p>A hangar is a closed building structure to hold aircraft, spacecraft or tanks in protective storage.</p>
                                <a class="read-more" [routerLink]="['/doors']"
                                [queryParams]="{page: 'hangar'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Doors/industrial-sectional-doors.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Industrial Sectional Doors</h3>
                                <p>Industrial Sectional Doors are vertically-opening doors constructed using multiple panel sections built to withstand heavy usage using double skinned steel</p>
                                <a class="read-more" [routerLink]="['/doors']"
                                [queryParams]="{page: 'ind-sectional-doors'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Doors/ind-sliding.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Industrial Sliding Doors</h3>
                                <p>
                                    GATEMAAN offer sliding doors for almost every application from a simple doorway size right up to aircraft hangar size. </p>
                                <a class="read-more" [routerLink]="['/doors']"
                                [queryParams]="{page: 'ind-sliding-doors'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Doors/sectional.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Sectional Doors</h3>
                                <p>Sectional doors are made of separate panels that lift upwards and rest parallel with the garage ceiling. </p>
                                <a class="read-more" [routerLink]="['/doors']"
                                [queryParams]="{page: 'sectional-doors'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Doors/ohd.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Sectional Over Head Doors</h3>
                                <p>Sectional Overhead Doors are one of the highest performance doors available for their sealing, insulation and general security properties.</p>
                                <a class="read-more" [routerLink]="['/doors']"
                                [queryParams]="{page: 'sectional-over-head-doors'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12 text-center">
                        <button class="default-btn btn-two" type="submit" [routerLink]="['/doors']"
                        [queryParams]="{page: 'bi-folding'}">
                            Explore More
                        </button>
                    </div>
                </div>
            </section>
        </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step == 4" class="wow fadeInUp animated animated" style="visibility: visible;"
        (opened)="setStep(4)" hideToggle>
        <mat-expansion-panel-header>
            <header>
                <div class="overlay"></div>
                <video [muted]="true" autoplay playsinline loop>
                    <source src="assets/videos/dock.mp4" type="video/mp4">
                </video>
                <!-- <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                  <source src="assets/EFASST.mp4" type="video/mp4">
                </video> -->
                <div class="container h-100">
                    <div class="d-flex h-100 text-center align-items-center">
                        <div class="w-100 text-white">
                            <h1 class="content">Loading Bay Equipment</h1> <br>
                            <hr class="style-two animate-flicker">
                            <div class="single-location video-btn">
                                <i class="bx bxs-hand-up"></i>
                            </div>
                            <!-- <h1 class="display-3">Video Header</h1>
                      <p class="lead mb-0">With HTML5 Video and Bootstrap 4</p> -->
                        </div>
                    </div>
                </div>
            </header>
            <!-- <div class="wrapper">
                <h1 class="content">Dock Levelers</h1>
                <hr class="style-two animate-flicker">
                <div class="sliding-background5">
                    <span id="overlay"></span>
                </div>
              </div> -->
        </mat-expansion-panel-header>
        <mat-form-field>
            <div class="spacer"></div>
            <section class="hosting-services-area pb-70">
                <div class="container">
                    <div class="section-title">
                        <h2>Loading Bay Equipment</h2>
                        <p>Loading bay equipment is key to the efficient and safe handling of goods. Levellers, shelters,
                            loadhouses and other docking accessories make loading and unloading more convenient and
                            sustainable.</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Docks/levelers.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">
                                <h3>Dock Levelers</h3>
                                <p>Dock Leveler is used to bridge the difference in height and distance between the warehouse floor & vehicle in the most efficient way. </p>
                                <a class="read-more" [routerLink]="['/dock']"
                                [queryParams]="{page: 'dock-levellers'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="technology-icon-bg">
                                <img src="assets/img/Docks/shelter.jpg" class="pro-img" alt="Image" type="image/jpeg">
                            </div>
                            <div class="single-technology">

                                <h3>Dock Shelter</h3>
                                <p>Dock shelters are enclosures that provide a wiper seal against the trailer top and sides for high sealing efficiency. </p>
                                <a class="read-more" [routerLink]="['/dock']"
                                [queryParams]="{page: 'dock-shelter'}">Read More <i
                                        class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>



                    </div>
                    <div class="col-md-12 col-sm-12 text-center">
                        <button class="default-btn btn-two" type="submit" [routerLink]="['/dock']"
                        [queryParams]="{page: 'dock-levellers'}">
                            Explore More
                        </button>
                    </div>
                </div>
            </section>
        </mat-form-field>
    </mat-expansion-panel>


</mat-accordion>
