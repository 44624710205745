import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from '../../../services/meta-service.service';
@Component({
  selector: 'app-shutters',
  templateUrl: './shutters.component.html',
  styleUrls: ['./shutters.component.scss']
})
export class ShuttersComponent implements OnInit {
    page: any;
    aluminiumwhite  = true;
    aluminiumblack = false;

    ssblack = true;
    sswhite = false;

    galvalumeblack = true;
    galvalumewhite = false;

    polycarbonateblack = true;
    polycarbonatewhite = false;

    perforatedblack = true;
    perforatedwhite = false;

    galvanisedblack = true;
    galvanisedwhite = false;

    fireblack = true;
    firewhite = false;

    pvcblack = true;
    pvcwhite = false;

    constructor(private route: ActivatedRoute, private metaService: MetaService) { }
    ngOnInit(): void {
        this.metaService.setCanonicalURL();
      this.route.queryParams.subscribe(params => {
          this.page = params.page;
      });  }

      turnWhite(imgname) {
        if (imgname === 'aluminium') {
            this.aluminiumwhite  = true;
            this.aluminiumblack = false;

            this.ssblack = true;
            this.sswhite = false;

            this.galvalumeblack = true;
            this.galvalumewhite = false;

            this.polycarbonateblack = true;
            this.polycarbonatewhite = false;

            this.perforatedblack = true;
            this.perforatedwhite = false;

            this.galvanisedblack = true;
            this.galvanisedwhite = false;

            this.fireblack = true;
            this.firewhite = false;

            this.pvcblack = true;
            this.pvcwhite = false;
        } else if (imgname === 'ss') {
            this.aluminiumwhite  = false;
            this.aluminiumblack = true;

            this.ssblack = false;
            this.sswhite = true;

            this.galvalumeblack = true;
            this.galvalumewhite = false;

            this.polycarbonateblack = true;
            this.polycarbonatewhite = false;

            this.perforatedblack = true;
            this.perforatedwhite = false;

            this.galvanisedblack = true;
            this.galvanisedwhite = false;

            this.fireblack = true;
            this.firewhite = false;

            this.pvcblack = true;
            this.pvcwhite = false;
        } else if (imgname === 'galvalume') {
            this.aluminiumwhite  = false;
            this.aluminiumblack = true;

            this.ssblack = true;
            this.sswhite = false;

            this.galvalumeblack = false;
            this.galvalumewhite = true;

            this.polycarbonateblack = true;
            this.polycarbonatewhite = false;

            this.perforatedblack = true;
            this.perforatedwhite = false;

            this.galvanisedblack = true;
            this.galvanisedwhite = false;

            this.fireblack = true;
            this.firewhite = false;

            this.pvcblack = true;
            this.pvcwhite = false;
        } else if (imgname === 'polycarbonate') {
            this.aluminiumwhite  = false;
            this.aluminiumblack = true;

            this.ssblack = true;
            this.sswhite = false;

            this.galvalumeblack = true;
            this.galvalumewhite = false;

            this.polycarbonateblack = false;
            this.polycarbonatewhite = true;

            this.perforatedblack = true;
            this.perforatedwhite = false;

            this.galvanisedblack = true;
            this.galvanisedwhite = false;

            this.fireblack = true;
            this.firewhite = false;

            this.pvcblack = true;
            this.pvcwhite = false;
        } else if (imgname === 'perforated') {
            this.aluminiumwhite  = false;
            this.aluminiumblack = true;

            this.ssblack = true;
            this.sswhite = false;

            this.galvalumeblack = true;
            this.galvalumewhite = false;

            this.polycarbonateblack = true;
            this.polycarbonatewhite = false;

            this.perforatedblack = false;
            this.perforatedwhite = true;

            this.galvanisedblack = true;
            this.galvanisedwhite = false;

            this.fireblack = true;
            this.firewhite = false;

            this.pvcblack = true;
            this.pvcwhite = false;
          } else if (imgname === 'galvanised') {
            this.aluminiumwhite  = false;
            this.aluminiumblack = true;

            this.ssblack = true;
            this.sswhite = false;

            this.galvalumeblack = true;
            this.galvalumewhite = false;

            this.polycarbonateblack = true;
            this.polycarbonatewhite = false;

            this.perforatedblack = true;
            this.perforatedwhite = false;

            this.galvanisedblack = false;
            this.galvanisedwhite = true;

            this.fireblack = true;
            this.firewhite = false;

            this.pvcblack = true;
            this.pvcwhite = false;

        } else if (imgname === 'fire-rated') {

            this.aluminiumwhite  = false;
            this.aluminiumblack = true;

            this.ssblack = true;
            this.sswhite = false;

            this.galvalumeblack = true;
            this.galvalumewhite = false;

            this.polycarbonateblack = true;
            this.polycarbonatewhite = false;

            this.perforatedblack = true;
            this.perforatedwhite = false;

            this.galvanisedblack = true;
            this.galvanisedwhite = false;

            this.fireblack = false;
            this.firewhite = true;

            this.pvcblack = true;
            this.pvcwhite = false;

         } else if (imgname === 'pvc') {

            this.aluminiumwhite  = false;
            this.aluminiumblack = true;

            this.ssblack = true;
            this.sswhite = false;

            this.galvalumeblack = true;
            this.galvalumewhite = false;

            this.polycarbonateblack = true;
            this.polycarbonatewhite = false;

            this.perforatedblack = true;
            this.perforatedwhite = false;

            this.galvanisedblack = true;
            this.galvanisedwhite = false;

            this.fireblack = true;
            this.firewhite = false;

            this.pvcblack = false;
            this.pvcwhite = true;
        } else {
            this.aluminiumwhite  = true;
            this.aluminiumblack = false;

            this.ssblack = true;
            this.sswhite = false;

            this.galvalumeblack = true;
            this.galvalumewhite = false;

            this.polycarbonateblack = true;
            this.polycarbonatewhite = false;

            this.perforatedblack = true;
            this.perforatedwhite = false;

            this.galvanisedblack = true;
            this.galvanisedwhite = false;

            this.fireblack = true;
            this.firewhite = false;

            this.pvcblack = true;
            this.pvcwhite = false;
        }

    }
}
