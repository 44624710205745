<!-- <app-high-speed-doors></app-high-speed-doors> -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 wow fadeInUp animated animated" style="visibility: visible;">
                    <h2>Our Products</h2>
                </div>

                <div class="col-lg-6 col-md-6 wow fadeInUp animated animated" style="visibility: visible;">
                    <ul>
                        <li><a routerLink="/home">Home </a></li>
                        <li>Products</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area ptb-100 feedback-area-two section-padding bg-custom-primary">

    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Our Products</h2>
            <!-- <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p> -->
        </div>

        <div class="row">
            <!--High Speed Doors-->
            <div class="col-lg-4 col-md-6 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="single-blog-post ">
                    <a [routerLink]="['/high-speed-doors']" [queryParams]="{page: 'sst'}" class="d-block text-center">
                        <div  class="pricing-shape1"><img  src="assets/img/pricing-shape1.png" alt="Image" type="image/png"></div>
                        <img src="assets/img/products/HSD1.jpg" class="gates-pics" alt="Image" type="image/jpeg">
                    </a>

                    <div class="blog-content-wrap">

                        <h3><a [routerLink]="['/high-speed-doors']" [queryParams]="{page: 'sst'}">High-Speed Doors</a></h3>
                        <p class="prod-desc">High speed doors excel during intense operation and in demanding conditions. Fast-acting fabric and insulated doors can improve efficiency, safety and hygiene in </p>

                        <a class="read-more" [routerLink]="['/high-speed-doors']" [queryParams]="{page: 'sst'}">Read More
                            <i class="bx bx-chevron-right"></i>
                            <div  class="pricing-shape2"><img  src="assets/img/pricing-shape2.png" alt="Image" type="image/png"></div>
                        </a>

                    </div>
                </div>
            </div>
            <!--Airport - Special-->
            <div class="col-lg-4 col-md-6 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="single-blog-post ">
                    <a [routerLink]="['/rapid doors']" [queryParams]="{page: 'freezer doors'}" class="d-block text-center">
                        <div  class="pricing-shape1"><img  src="assets/img/pricing-shape1.png" alt="Image" type="image/png"></div>
                        <img src="assets/img/products/Airport.jpg" class="gates-pics" alt="Image" type="image/jpeg">
                    </a>

                    <div class="blog-content-wrap">


                        <h3><a [routerLink]="['/rapid doors']" [queryParams]="{page: 'freezer doors'}">HSD - Airport Special</a></h3>
                        <p class="prod-desc">Designed bespoke for areas including baggage handling, catering zones, and emergency services they all can help to reduce energy usage, improve user safety, </p>

                        <a class="read-more" [routerLink]="['/rapid doors']" [queryParams]="{page: 'freezer doors'}">Read More
                            <i class="bx bx-chevron-right"></i>
                            <div  class="pricing-shape2"><img  src="assets/img/pricing-shape2.png" alt="Image" type="image/png"></div>
                        </a>

                    </div>
                </div>
            </div>
            <!--High Speed Rapid Doors-->
            <div class="col-lg-4 col-md-6 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="single-blog-post ">
                    <a [routerLink]="['/rapiddoors']" [queryParams]="{page: 'freezer-doors'}" class="d-block text-center">
                        <div  class="pricing-shape1"><img  src="assets/img/pricing-shape1.png" alt="Image" type="image/png"></div>
                        <img src="assets/img/products/fabricdoors.jpg" class="gates-pics" alt="Image" type="image/jpeg">
                    </a>

                    <div class="blog-content-wrap">

                        <h3><a [routerLink]="['/rapiddoors']" [queryParams]="{page: 'freezer-doors'}">Rapid Doors</a></h3>
                        <p class="prod-desc">High speed rapid roll doors are engineered for high performance in demanding industrial environments. These robust doors provide efficient access while </p>

                        <a class="read-more" [routerLink]="['/rapiddoors']" [queryParams]="{page: 'freezer-doors'}">Read More
                            <i class="bx bx-chevron-right"></i>
                            <div  class="pricing-shape2"><img  src="assets/img/pricing-shape2.png" alt="Image" type="image/png"></div>
                        </a>

                    </div>
                </div>
            </div>
            <!--Rolling Shutters-->
            <div class="col-lg-4 col-md-6 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="single-blog-post ">

                    <a [routerLink]="['/shutters']" [queryParams]="{page: 'aluminium'}" class="d-block text-center">
                        <div  class="pricing-shape1"><img  src="assets/img/pricing-shape1.png" alt="Image" type="image/png"></div>
                        <img src="assets/img/products/shutter.jpg" class="gates-pics" alt="Image" type="image/jpeg">
                    </a>

                    <div class="blog-content-wrap">

                        <h3><a [routerLink]="['/shutters']" [queryParams]="{page: 'aluminium'}">Rolling Shutters</a></h3>
                        <p class="prod-desc">A roller shutter, coiling door, roller door or sectional overhead door is a type of door or window shutter consisting of many horizontal slats hinged together</p>

                        <a class="read-more" [routerLink]="['/shutters']" [queryParams]="{page: 'aluminium'}">Read More
                            <i class="bx bx-chevron-right"></i>
                            <div  class="pricing-shape2"><img  src="assets/img/pricing-shape2.png" alt="Image" type="image/png"></div>
                        </a>

                    </div>
                </div>
            </div>
            <!--Gates-->
            <div class="col-lg-4 col-md-6 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="single-blog-post ">
                    <a [routerLink]="['/gates']" [queryParams]="{page: 'sliding'}" class="d-block text-center">
                        <div  class="pricing-shape1"><img  src="assets/img/pricing-shape1.png" alt="Image" type="image/png"></div>
                        <img src="assets/img/products/gates.jpeg" class="gates-pics" alt="Image" type="image/jpeg">
                    </a>

                    <div class="blog-content-wrap">

                        <h3><a [routerLink]="['/gates']" [queryParams]="{page: 'sliding'}">Automated Gates</a></h3>
                        <p class="prod-desc">An Automatic gate is an entrance gate which can be opened and closed via an electric powered mechanism.An automated gate that has not been fitted with</p>

                        <a class="read-more" [routerLink]="['/gates']" [queryParams]="{page: 'sliding'}">Read More
                            <i class="bx bx-chevron-right"></i>
                            <div  class="pricing-shape2"><img  src="assets/img/pricing-shape2.png" alt="Image" type="image/png"></div>
                        </a>

                    </div>
                </div>
            </div>
            <!--Doors-->
            <div class="col-lg-4 col-md-6 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="single-blog-post ">

                    <a [routerLink]="['/doors']" [queryParams]="{page: 'bi-folding'}" class="d-block text-center">
                        <div  class="pricing-shape1"><img  src="assets/img/pricing-shape1.png" alt="Image" type="image/png"></div>
                        <img src="assets/img/products/sectional door.jpg" class="gates-pics" alt="Image" type="image/jpeg">
                    </a>

                    <div class="blog-content-wrap">
                        <h3><a [routerLink]="['/doors']" [queryParams]="{page: 'bi-folding'}">Doors</a></h3>
                        <p class="prod-desc">Doors are usually made in several jointed panels that roll up on tracks across the garage ceiling, or into a roll above the doorway.</p>
                        <a class="read-more" [routerLink]="['/doors']" [queryParams]="{page: 'bi-folding'}">Read More
                            <i class="bx bx-chevron-right"></i>
                            <div  class="pricing-shape2"><img  src="assets/img/pricing-shape2.png" alt="Image" type="image/png"></div>
                        </a>
                    </div>
                </div>
            </div>
            <!--Docks-->
            <div class="col-lg-4 col-md-6 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="single-blog-post ">
                    <a [routerLink]="['/dock']" [queryParams]="{page: 'dock-levelers'}" class="d-block text-center">
                        <div  class="pricing-shape1"><img  src="assets/img/pricing-shape1.png" alt="Image" type="image/png"></div>
                        <img src="assets/img/products/docks.jpg" class="gates-pics" alt="Image" type="image/jpeg">
                    </a>
                    <div class="blog-content-wrap">
                        <h3><a [routerLink]="['/dock']" [queryParams]="{page: 'dock-levelers'}">Loading Bay Equipment</a></h3>
                        <p class="prod-desc">Loading bay equipment is key to the efficient and safe handling of goods. Levellers, shelters, loadhouses and other docking accessories make loading</p>

                        <a class="read-more" [routerLink]="['/dock']" [queryParams]="{page: 'dock-levelers'}">Read More
                            <i class="bx bx-chevron-right"></i>
                            <div  class="pricing-shape2"><img  src="assets/img/pricing-shape2.png" alt="Image" type="image/png"></div>
                        </a>
                    </div>
                </div>
            </div>
            <!--Safety Devices-->
            <div class="col-lg-4 col-md-6 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="single-blog-post ">
                    <a [routerLink]="['/coming-soon']"  class="d-block text-center">
                        <div  class="pricing-shape1"><img  src="assets/img/pricing-shape1.png" alt="Image" type="image/png"></div>
                        <img src="assets/img/products/safety device.jpg" class="gates-pics" alt="Image" type="image/jpeg">
                    </a>
                    <div class="blog-content-wrap">
                        <h3><a [routerLink]="['/coming-soon']">Safety Devices</a></h3>
                        <p class="prod-desc">GATEMAAN offers a large selection of innovative accessories and an extensive range of peripheral products to help you to control your door systems.</p>

                        <a class="read-more" [routerLink]="['/coming-soon']"  >Read More
                            <i class="bx bx-chevron-right"></i>
                            <div  class="pricing-shape2"><img  src="assets/img/pricing-shape2.png" alt="Image" type="image/png"></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
