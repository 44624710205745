import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from '../../../services/meta-service.service';
@Component({
  selector: 'app-doors',
  templateUrl: './doors.component.html',
  styleUrls: ['./doors.component.scss']
})
export class DoorsComponent implements OnInit {
    page: any;
    biwhite = true;
    biblack = false;

    foldingblack = true;
    foldingwhite = false;

    hangarblack = true;
    hangarwhite = false;

    indsecblack = true;
    indsecwhite = false;

    indsliblack = true;
    indsliwhite = false;

    secblack = true;
    secwhite = false;

    secovblack = true;
    secovwhite = false;

  constructor(private route: ActivatedRoute, private metaService: MetaService) { }
  ngOnInit(): void {
    this.metaService.setCanonicalURL();
    this.route.queryParams.subscribe(params => {
        this.page = params.page;
    });  }

    turnWhite(imgname) {
        if (imgname === 'bi') {
            this.biwhite = true;
            this.biblack = false;

            this.foldingblack = true;
            this.foldingwhite = false;

            this.hangarblack = true;
            this.hangarwhite = false;

            this.indsecblack = true;
            this.indsecwhite = false;

            this.indsliblack = true;
            this.indsliwhite = false;

            this.secblack = true;
            this.secwhite = false;

            this.secovblack = true;
            this.secovwhite = false;
        } else if (imgname === 'folding') {
            this.biwhite = false;
            this.biblack = true;

            this.foldingblack = false;
            this.foldingwhite = true;

            this.hangarblack = true;
            this.hangarwhite = false;

            this.indsecblack = true;
            this.indsecwhite = false;

            this.indsliblack = true;
            this.indsliwhite = false;

            this.secblack = true;
            this.secwhite = false;

            this.secovblack = true;
            this.secovwhite = false;
        } else if (imgname === 'hangar') {
            this.biwhite = false;
            this.biblack = true;

            this.foldingblack = true;
            this.foldingwhite = false;

            this.hangarblack = false;
            this.hangarwhite = true;

            this.indsecblack = true;
            this.indsecwhite = false;

            this.indsliblack = true;
            this.indsliwhite = false;

            this.secblack = true;
            this.secwhite = false;

            this.secovblack = true;
            this.secovwhite = false;
        } else if (imgname === 'indsec') {
            this.biwhite = false;
            this.biblack = true;

            this.foldingblack = true;
            this.foldingwhite = false;

            this.hangarblack = true;
            this.hangarwhite = false;

            this.indsecblack = false;
            this.indsecwhite = true;

            this.indsliblack = true;
            this.indsliwhite = false;

            this.secblack = true;
            this.secwhite = false;

            this.secovblack = true;
            this.secovwhite = false;
        } else if (imgname === 'indsli') {
            this.biwhite = false;
            this.biblack = true;

            this.foldingblack = true;
            this.foldingwhite = false;

            this.hangarblack = true;
            this.hangarwhite = false;

            this.indsecblack = true;
            this.indsecwhite = false;

            this.indsliblack = false;
            this.indsliwhite = true;

            this.secblack = true;
            this.secwhite = false;

            this.secovblack = true;
            this.secovwhite = false;
        } else if (imgname === 'sec') {
            this.biwhite = false;
            this.biblack = true;

            this.foldingblack = true;
            this.foldingwhite = false;

            this.hangarblack = true;
            this.hangarwhite = false;

            this.indsecblack = true;
            this.indsecwhite = false;

            this.indsliblack = true;
            this.indsliwhite = false;

            this.secblack = false;
            this.secwhite = true;

            this.secovblack = true;
            this.secovwhite = false;


        } else if (imgname === 'secov') {
            this.biwhite = false;
            this.biblack = true;

            this.foldingblack = true;
            this.foldingwhite = false;

            this.hangarblack = true;
            this.hangarwhite = false;

            this.indsecblack = true;
            this.indsecwhite = false;

            this.indsliblack = true;
            this.indsliwhite = false;

            this.secblack = true;
            this.secwhite = false;

            this.secovblack = false;
            this.secovwhite = true;


        } else {
            this.biwhite = true;
            this.biblack = false;

            this.foldingblack = true;
            this.foldingwhite = false;

            this.hangarblack = true;
            this.hangarwhite = false;

            this.indsecblack = true;
            this.indsecwhite = false;

            this.indsliblack = true;
            this.indsliwhite = false;

            this.secblack = true;
            this.secwhite = false;

            this.secovblack = true;
            this.secovwhite = false;
        }

    }
}
