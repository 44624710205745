<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Stacking Doors</h2>
            <p>GATEMAAN High-speed freezer doors are specifically designed to provide fast access to freezing or cold storage areas while maintaining the internal temperature. These doors are essential in environments like cold storage warehouses, food processing plants, and pharmaceutical facilities, where temperature control is crucial            </p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                    style="visibility: visible;">
                    <img src="assets/img/products/rapid doors/freezer.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                </div>

                    <div class="article-content">
                        <h3>About Freezer Doors</h3>
                        <p>Essentially, bifold doors are made up of multiple leaves which fold back on themselves in order to open up an entire aperture. The doors are fitted to runners, and when opened they slide and fold at the same time for seamless operation.
                        </p>

                        <ul class="feat" align="justify" style="line-height:180%">
                            <li>Bi-folding doors are a stylish and versatile door system that offers a unique way to open up spaces and seamlessly connect indoor and outdoor areas.
                            </li>
                            <li>One of the standout features of bi-folding doors is their ability to maximize natural light and provide unobstructed views. 
                            </li>
                            <li>The panels of bi-folding doors are connected by durable hinges, which allow them to fold and stack against one or both sides of the opening when fully opened.
                            </li>
                            <li>This folding mechanism enables the doors to occupy minimal space and create a seamless transition between indoor and outdoor living areas. 
                            </li>
                            </ul>
                    </div>

            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9 side-icon" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>
            <blockquote class="flaticon-quote">
                <table class="table">
     <thead class="table-light">
       <tr>
         <th scope="col">Product : </th>
         <th scope="col">Freezer Door</th>
       </tr>
     </thead>
     <tbody>
       <tr>
         <th scope="row" color="#fe9900">Door Dimension: </th>
         <td></td>
       </tr>
       <tr>
         <th scope="row">Coming Soon</th>
         <td></td>
       </tr> 
     </tbody>
   </table>  
 </blockquote>
        </div>

    </div>
</section>
