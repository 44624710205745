import { Component, OnInit} from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
//import { MetaService } from '../../../services/meta-service.service';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
    step = 0;

    setStep(index: number) {
      this.step = index;
    }

    nextStep() {
      this.step++;
    }    

    prevStep() {
      this.step--;
    }
  constructor(
    private titleService: Title, 
    private metaService: Meta,
    private activatedRoute: ActivatedRoute
  ){}
  ngOnInit(): void {
   // this.metaService.setCanonicalURL();
   this.activatedRoute.data.subscribe((data: any) => {
    const pageTitle = data.pageTitle || 'Gatemaan: Trusted Manufacturers of Industrial Doors, Entrance Gates, and Rolling Shutters in India';
    const pageDescription = data.pageDescription || 'Explore Gatemaan, the leading manufacturers of sectional doors, folding doors, and sliding doors in India. Discover durable, high-quality, and customizable door solutions for industrial and commercial needs.';
    const suggestedURL = data.suggestedURL || 'https://www.gatemaan.com/';

    // Set the title
    this.titleService.setTitle(pageTitle);

    // Set the meta description
    this.metaService.updateTag({
      name: 'description',
      content: pageDescription
    });

    // Set the canonical URL
    this.metaService.updateTag({
      rel: 'canonical',
      href: suggestedURL
    });

    // Set Open Graph URL (for social sharing)
    this.metaService.updateTag({
      property: 'og:url',
      content: suggestedURL
    });
  });
  }

}
