<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Rapid Doors</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/home">Home</a></li>
                        <li><a routerLink="/products">Products</a></li>
                        <li>Rapid Doors</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="contact-info-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="h2-cus">Rapid Doors</h2>
            <!-- </div>
        <div class="txt-center"> -->
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">

                <li class="nav-item  ">
                    <a class="nav-link" id="freezer-tab" (click)="turnWhite('freezer')"  [ngClass]="{'active' : page == 'freezer'}" data-toggle="pill" 
                        href="#freezer" role="tab" aria-controls="freezer" aria-selected="false"> <i class="">
                            <img [hidden]="!freezerblack" class="cus-icon" src="assets/img/icons/Rapiddoors/freezerblack.png" alt="Image">
                            <img [hidden]="!freezerwhite" class="cus-icon" src="assets/img/icons/Rapiddoors/freezerwhite.png" alt="Image">
                        </i>
                        </a>
                    </li>
                    <li class="nav-item ">
                        <a class="nav-link" id="freezerplus-tab" (click)="turnWhite('freezerplus')" [ngClass]="{'active' : page == 'freezerplus'}" data-toggle="pill" href="#freezerplus" role="tab"
                            aria-controls="freezerplus" aria-selected="false">   
                            <i class="">
                                <img [hidden]="!freezerplusblack" class="cus-icon" src="assets/img/icons/Rapiddoors/freezerplusblack.png" alt="Image">
                            <img [hidden]="!freezerpluswhite" class="cus-icon" src="assets/img/icons/Rapiddoors/freezerpluswhite.png" alt="Image">
                        
                            </i>
                        </a>
                    </li>
                    <li class="nav-item  ">
                        <a class="nav-link arrow_box" id="rollup-tab" (click)="turnWhite('rollup')" [ngClass]="{'active' : page == 'rollup'}" data-toggle="pill" href="#rollup" role="tab"
                            aria-controls="rollup" aria-selected="true">   
							<i class="">
							<img class="cus-icon" [hidden]="!rollupblack" src="assets/img/icons/Rapiddoors/rollupblack.png" alt="Image">
                            <img class="cus-icon" [hidden]="!rollupwhite" src="assets/img/icons/Rapiddoors/rollupwhite.png" alt="Image">
							</i>
                        </a>
                    </li>
                    <li class="nav-item  ">
                        <a class="nav-link" id="stacking-tab" (click)="turnWhite('stacking')" [ngClass]="{'active' : page == 'stacking'}"data-toggle="pill" href="#stacking" role="tab"
                            aria-controls="stacking" aria-selected="false">   
							<i class="">
							<img class="cus-icon" [hidden]="!stackingblack" src="assets/img/icons/Rapiddoors/stackingblack.png" alt="Image">
                            <img class="cus-icon" [hidden]="!stackingwhite" src="assets/img/icons/Rapiddoors/stackingwhite.png" alt="Image">
							</i>
                        </a>
                    </li>
                    <li class="nav-item  ">
                        <a class="nav-link" id="zipper-tab" (click)="turnWhite('zipper')" [ngClass]="{'active' : page == 'zipper'}" data-toggle="pill" href="#zipper" role="tab"
                            aria-controls="zipper" aria-selected="false">   
							<i class="">
							<img class="cus-icon" [hidden]="!zipperblack" src="assets/img/icons/Rapiddoors/zipperblack.png" alt="Image">
                            <img class="cus-icon" [hidden]="!zipperwhite" src="assets/img/icons/Rapiddoors/zipperwhite.png" alt="Image">
							</i>
                        </a>
                    </li>
                    <li class="nav-item  ">
                        <a class="nav-link" id="megagiant-tab" (click)="turnWhite('megagiant')" [ngClass]="{'active' : page == 'megagiant'}" data-toggle="pill" href="#megagiant" role="tab"
                            aria-controls="megagiant" aria-selected="false">   
							<i class="">
							<img class="cus-icon" [hidden]="!megagiantblack" src="assets/img/icons/Rapiddoors/megagiantblack.png" alt="Image">
                            <img class="cus-icon" [hidden]="!megagiantwhite" src="assets/img/icons/Rapiddoors/megagiantwhite.png" alt="Image">
							</i>
                        </a>
                    </li>
                </ul>

            </div>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade" [ngClass]="{'show active' : page == 'freezer'}" id="freezer" role="tabpanel" 
                    aria-labelledby="freezer-tab">
					<app-freezer-doors></app-freezer-doors>
                    </div>
                    <div class="tab-pane fade" [ngClass]="{'show active' : page == 'freezerplus'}" id="freezerplus" role="tabpanel" 
                    aria-labelledby="freezerplus-tab">
                    <app-freezerplus-doors></app-freezerplus-doors>
                    </div>
                    <div class="tab-pane fade" [ngClass]="{'show active' : page == 'rollup'}" id="rollup" role="tabpanel" aria-labelledby="rollup-tab">
                        <app-rollup-doors></app-rollup-doors>
                    </div>
                    <div class="tab-pane fade" [ngClass]="{'show active' : page == 'stacking'}" id="stacking" role="tabpanel" aria-labelledby="stacking-tab">
                        <app-stacking-doors></app-stacking-doors>
                    </div>
                    <div class="tab-pane fade" [ngClass]="{'show active' : page == 'zipper'}" id="zipper" role="tabpanel" aria-labelledby="zipper-tab">
                        <app-zipper-doors></app-zipper-doors>
                    </div>
                    <div class="tab-pane fade" [ngClass]="{'show active' : page == 'megagiant'}" id="megagiant" role="tabpanel" aria-labelledby="megagiant-tab">
                        <app-megagiant-doors></app-megagiant-doors>
                    </div>
                </div>
    </div>
</section>
