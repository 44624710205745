<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>High Speed Doors</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/home">Home</a></li>
                        <li><a routerLink="/products">Products</a></li>
                        <li>High Speed Doors</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="contact-info-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="h2-cus">HIGH SPEED DOORS</h2>
        <!-- </div>
        <div class="txt-center"> -->

            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">

                <li class="nav-item ">
                    <a class="nav-link" (click)="turnWhite('sst')" id="efa-sst-tab" [ngClass]="{'active' : page == 'sst'}" data-toggle="pill"
                        href="#efa-sst" role="tab" aria-controls="efa-sst" aria-selected="false"> <i class="">
                            <img [hidden]="!sstblack" class="cus-icon" src="assets/img/High Speed/SST/sst.jpg" alt="Image">
                            <img [hidden]="!sstwhite" class="cus-icon" src="assets/img/High Speed/SST/sst.jpg" alt="Image"></i>

                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="turnWhite('stt')" id="efa-stt-tab" [ngClass]="{'active' : page == 'stt'}" data-toggle="pill"
                        href="#efa-stt" role="tab" aria-controls="efa-stt" aria-selected="false"> <i class="">
                            <img [hidden]="!sttblack" class="cus-icon" src="assets/img/High Speed/STT/stt.jpg" alt="Image">
                            <img [hidden]="!sttwhite" class="cus-icon" src="assets/img/High Speed/STT/stt.jpg" alt="Image">
                        </i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link arrow_box " (click)="turnWhite('srt')"  [ngClass]="{'active' : page == 'srt'}" id="efa-srt-tab"
                        data-toggle="pill" href="#efa-srt" role="tab" aria-controls="efa-srt" aria-selected="true">
                        <i class=""> <img [hidden]="!srtblack" class="cus-icon" src="assets/img/High Speed/SRT/srt.jpg" alt="Image">
                            <img [hidden]="!srtwhite" class="cus-icon" src="assets/img/High Speed/SRT/srt.jpg" alt="Image">
                        </i>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="efa-str-tab" (click)="turnWhite('str')" [ngClass]="{'active' : page == 'str'}" data-toggle="pill"
                        href="#efa-str" role="tab" aria-controls="efa-str" aria-selected="false"> <i class="">
                            <img [hidden]="!strblack" class="cus-icon" src="assets/img/High Speed/STR/str.jpg" alt="Image">
                            <img [hidden]="!strwhite" class="cus-icon" src="assets/img/High Speed/STR/str.jpg" alt="Image">
                        </i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="efa-srt-mtl-tab" (click)="turnWhite('mtl')"[ngClass]="{'active' : page == 'srt-mtl'}"
                        data-toggle="pill" href="#efa-srt-mtl" role="tab" aria-controls="efa-srt-mtl"
                        aria-selected="false"> <i class="">
                            <img [hidden]="!mtlblack" class="cus-icon" src="assets/img/High Speed/MTL/mtl.jpg" alt="Image">
                            <img [hidden]="!mtlwhite" class="cus-icon" src="assets/img/High Speed/MTL/mtl.jpg" alt="Image">
                        </i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="efa-pheriperi-tab" (click)="turnWhite('pheriperi')"[ngClass]="{'active' : page == 'pheriperi'}"
                        data-toggle="pill" href="#efa-pheriperi" role="tab" aria-controls="efa-pheriperi"
                        aria-selected="false"> <i class="">
                            <img [hidden]="!mtlblack" class="cus-icon" src="assets/img/High Speed/SFT/control-box.jpg" alt="Image">
                            <img [hidden]="!mtlwhite" class="cus-icon" src="assets/img/High Speed/SFT/control-box.jpg" alt="Image">
                        </i></a>
                </li>
            </ul>

        </div>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'srt'}" id="efa-srt" role="tabpanel"
                aria-labelledby="efa-srt-tab">
                <app-efa-srt></app-efa-srt>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'sst'}" id="efa-sst" role="tabpanel"
                aria-labelledby="efa-sst-tab">
                <app-efa-sst></app-efa-sst>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'stt'}" id="efa-stt" role="tabpanel"
                aria-labelledby="efa-stt-tab">
                <app-efa-stt></app-efa-stt>
            </div>

            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'str'}" id="efa-str" role="tabpanel"
                aria-labelledby="efa-str-tab">
                <app-efa-str></app-efa-str>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'srt-mtl'}" id="efa-srt-mtl" role="tabpanel"
                aria-labelledby="efa-srt-mtl-tab">
                <app-efa-srt-mtl></app-efa-srt-mtl>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'pheriperi'}" id="efa-pheriperi" role="tabpanel"
            aria-labelledby="efa-pheriperi-tab">
            <app-periphery></app-periphery>
        </div>
        </div>
    </div>
</section>
